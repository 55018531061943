import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Button, TogglableTextInput } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import Avatar from '@noloco/core/src/components/Avatar';
import { FREE } from '@noloco/core/src/constants/accountPlans';
import { updateTeam as updateTeamAction } from '@noloco/core/src/reducers/user';
import { isTrialActive } from '@noloco/core/src/utils/billing';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getFullName } from '@noloco/core/src/utils/user';
import { UPDATE_TEAM } from '../queries/teams';
import InviteUserToWorkspaceModal from './workspaces/InviteUserToWorkspaceModal';

const LANG_KEY = 'workspaces.settings';

const WorkspaceSettings = () => {
  const {
    query: { workspace: workspaceId },
  } = useRouter();

  const dispatch = useDispatch();
  const { user } = useDashboardAuth();
  const [updateTeam, { loading }] = useMutation(UPDATE_TEAM);
  const errorAlert = useGraphQlErrorAlert();

  const workspace = useMemo(
    () => user!.teams.find((team) => team.id === workspaceId),
    [user, workspaceId],
  );

  const onSaveWorkspaceName = useCallback(
    (newWorkspaceName: string | number) => {
      if (workspace && newWorkspaceName !== workspace?.name) {
        updateTeam({
          variables: {
            id: parseInt(workspace.id as string, 10),
            name: newWorkspaceName,
          },
        })
          .then((result) => {
            const updatedWorkspace = result.data.updateTeam;
            dispatch(updateTeamAction(updatedWorkspace));
          })
          .catch((error) => {
            errorAlert(getText(LANG_KEY, 'update.error'), error);
          });
      }
    },
    [dispatch, errorAlert, updateTeam, workspace],
  );

  const isTrialing = useMemo(
    () => workspace && isTrialActive(workspace.plan),
    [workspace],
  );

  if (!workspace) {
    return null;
  }

  return (
    <div className="mx-auto flex w-full max-w-screen-lg flex-col p-8">
      <div className="flex w-full items-center justify-between">
        <TogglableTextInput
          className="mr-4 w-full text-2xl font-medium"
          loading={loading}
          value={workspace.name}
          surface={LIGHT}
          onSave={onSaveWorkspaceName}
        />
        {!isTrialing && workspace.plan.type !== FREE && (
          <Badge m={undefined}>
            <span className="text-sm">
              {getText('billing.plans', workspace.plan.type, 'name')}
            </span>
          </Badge>
        )}
        {workspace.plan.type === FREE && (
          <Link
            className="whitespace-nowrap text-sm text-pink-500 hover:underline"
            to={`/billing/${workspace.id}`}
          >
            {getText(LANG_KEY, 'upgrade')}
          </Link>
        )}
      </div>
      <div className="mt-4 flex items-center justify-between">
        <h2 className="text-lg font-medium">
          {getText(LANG_KEY, 'collaborators.title')}
        </h2>
        <Link to={`/workspaces/${workspace.id}/invite`}>
          <Button size={SM}>{getText(LANG_KEY, 'collaborators.invite')}</Button>
        </Link>
      </div>
      <div className="mt-2 flex flex-col space-y-2">
        {workspace.users.map((user) => (
          <div key={user.id} className="flex w-full items-center">
            <Avatar user={user} size={8} />
            <div className="ml-2 flex flex-col">
              {user.firstName && (
                <span className="text-sm font-medium">{getFullName(user)}</span>
              )}
              <span className="text-sm text-gray-600">{user.email}</span>
            </div>
            <div className="ml-auto flex flex-col pl-4 text-right"></div>
          </div>
        ))}
      </div>
      <Route path={`/workspaces/:workspace/invite`}>
        <InviteUserToWorkspaceModal workspace={workspace} />
      </Route>
    </div>
  );
};

export default WorkspaceSettings;
